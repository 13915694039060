@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@100;400;600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu+Mono&display=swap');

body {
  font-family: 'Exo 2', sans-serif;
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #f1f1f1;
}

#root {
  width: 100%;
  height: 100%;
  min-height: 100%;
}

input {
  outline: 0;
}

button {
  outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.animated-bubble {
  position: relative;
  animation-name: bubble-animation;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes bubble-animation {
  from {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(0, -10px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

.animated-speech {
  animation: speech-animation 4s infinite;
}

@keyframes speech-animation {
  0%   { box-shadow: rgba(0, 0, 0, 0.4) 0 7px 20px -6px, 0 0 0 3px rgb(0, 111, 255); }
  5%   { box-shadow: rgba(0, 0, 0, 0.4) 0 7px 20px -6px, 0 0 0 14px rgb(0, 111, 255); }
  10%  { box-shadow: rgba(0, 0, 0, 0.4) 0 7px 20px -6px, 0 0 0 10px rgb(0, 111, 255); }
  15%  { box-shadow: rgba(0, 0, 0, 0.4) 0 7px 20px -6px, 0 0 0 18px rgb(0, 111, 255); }
  20%  { box-shadow: rgba(0, 0, 0, 0.4) 0 7px 20px -6px, 0 0 0 22px rgb(0, 111, 255); }
  25%  { box-shadow: rgba(0, 0, 0, 0.4) 0 7px 20px -6px, 0 0 0 16px rgb(0, 111, 255); }
  30%  { box-shadow: rgba(0, 0, 0, 0.4) 0 7px 20px -6px, 0 0 0 26px rgb(0, 111, 255); }
  35%  { box-shadow: rgba(0, 0, 0, 0.4) 0 7px 20px -6px, 0 0 0 15px rgb(0, 111, 255); }
  40%  { box-shadow: rgba(0, 0, 0, 0.4) 0 7px 20px -6px, 0 0 0 20px rgb(0, 111, 255); }
  45%  { box-shadow: rgba(0, 0, 0, 0.4) 0 7px 20px -6px, 0 0 0 12px rgb(0, 111, 255); }
  50%  { box-shadow: rgba(0, 0, 0, 0.4) 0 7px 20px -6px, 0 0 0 24px rgb(0, 111, 255); }
  55%  { box-shadow: rgba(0, 0, 0, 0.4) 0 7px 20px -6px, 0 0 0 18px rgb(0, 111, 255); }
  60%  { box-shadow: rgba(0, 0, 0, 0.4) 0 7px 20px -6px, 0 0 0 10px rgb(0, 111, 255); }
  65%  { box-shadow: rgba(0, 0, 0, 0.4) 0 7px 20px -6px, 0 0 0 23px rgb(0, 111, 255); }
  70%  { box-shadow: rgba(0, 0, 0, 0.4) 0 7px 20px -6px, 0 0 0 16px rgb(0, 111, 255); }
  75%  { box-shadow: rgba(0, 0, 0, 0.4) 0 7px 20px -6px, 0 0 0 20px rgb(0, 111, 255); }
  80%  { box-shadow: rgba(0, 0, 0, 0.4) 0 7px 20px -6px, 0 0 0 26px rgb(0, 111, 255); }
  85%  { box-shadow: rgba(0, 0, 0, 0.4) 0 7px 20px -6px, 0 0 0 14px rgb(0, 111, 255); }
  90%  { box-shadow: rgba(0, 0, 0, 0.4) 0 7px 20px -6px, 0 0 0 18px rgb(0, 111, 255); }
  95%  { box-shadow: rgba(0, 0, 0, 0.4) 0 7px 20px -6px, 0 0 0 22px rgb(0, 111, 255); }
  100% { box-shadow: rgba(0, 0, 0, 0.4) 0 7px 20px -6px, 0 0 0 3px rgb(0, 111, 255); }
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
